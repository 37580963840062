/**
 * Check if key exists in local storage
 * @param  string key
 * @return boolean
 */
export function localStorageHas(key) {
  const item = localStorage.getItem(key);
  return item !== null;
}

/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
export function localStorageGet(key) {
  const item = localStorage.getItem(key);
  if (!item) return null;
  if (item[0] === '{' || item[0] === '[') return JSON.parse(item);
  return item;
}

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
export function localStorageSave(key, value) {
  if (value === undefined) return "Can't store undefinded value";
  let newValue = value;

  if (typeof value === 'object' || typeof value.isArray !== 'undefined') {
    newValue = JSON.stringify(value);
  }

  if (typeof newValue !== 'string') return "Can't store unrecognized format value";
  localStorage.setItem(key, newValue);
  return 'stored';
}

/**
 * Remove element from local storage.
 * @param string key
 */
export function localStorageRemove(key) {
  localStorage.removeItem(key);
}
