import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) =>
  function LoadableComponent(props) {
    const { pathname } = useLocation();
    return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    );
  };
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/user/list" replace />
    },
    {
      path: 'user',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/user/list" replace />,
          index: true
        },
        { path: 'list', element: <UserList /> },
        { path: ':name/edit', element: <UserCreate /> },

        { path: ':id', element: <UserDetail /> }
      ]
    },
    {
      path: 'sync',
      children: [
        { element: <Navigate to="/sync/user" replace />, index: true },
        { path: 'user', element: <div>Missing id</div> },
        { path: 'user/:id', element: <SyncContact /> },
        { path: 'contract/:id', element: <SyncContract /> }
      ]
    },
    {
      path: 'author',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/author/list" replace />,
          index: true
        },
        { path: 'list', element: <AuthorList /> },
        { path: ':id', element: <AuthorDetail /> },
        { path: 'create', element: <AuthorCreate /> }
      ]
    },
    {
      path: 'banner',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/banner/list" replace />,
          index: true
        },
        { path: 'list', element: <BannerList /> },
        { path: 'create', element: <BannerCreate /> },
        { path: ':id/edit', element: <BannerEdit /> }
      ]
    },
    {
      path: 'link-checker',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/link-checker/list" replace />,
          index: true
        },
        { path: 'list', element: <LinkCheckerList /> }
      ]
    },
    {
      path: 'marketing2',
      element: <DashboardLayout />,
      children: [
        { path: 'trials', element: <Trials /> },
        { path: 'trials/:id', element: <TrialDetail /> },
        { path: 'trials/add/single', element: <TrialCreateSingle /> },
        { path: 'trials/add/batch', element: <TrialCreateBatch /> }
      ]
    },
    {
      path: 'marketing',
      element: <DashboardLayout />,
      children: [
        { path: 'trials', element: <Trials /> },
        { path: 'trials/:id', element: <TrialDetail /> },
        { path: 'trials/add/single', element: <TrialCreateSingle /> },
        { path: 'trials/add/batch', element: <TrialCreateBatch /> }
      ]
    },
    {
      path: 'upload-media',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/upload-media/list" replace />,
          index: true
        },
        { path: 'list', element: <FreeUploadList /> },
        { path: 'create', element: <FreeUploadCreate /> }
      ]
    },
    {
      path: 'vtb',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/vtb/event/list" replace />,
          index: true
        },
        {
          path: 'event',
          children: [
            {
              element: <Navigate to="/vtb/event/list" replace />,
              index: true
            },
            { path: 'list', element: <EventList /> },
            { path: ':id', element: <p>Event detail</p> },
            { path: 'create', element: <p>Event create</p> },
            { path: ':id/edit', element: <p>Event edit</p> }
          ]
        }
      ]
    },
    {
      path: 'special-access',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/special-access/list" replace />,
          index: true
        },
        { path: 'list', element: <SpecialAccess /> },
        { path: 'invite-user', element: <SpecialAccessInviteUser /> },
        { path: 'give-direct-access', element: <SpecialAccessGiveDirectAccess /> }
      ]
    },
    {
      path: 'quality-system',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/quality-system/overview" replace />,
          index: true
        },
        { path: 'overview', element: <EvaluationOverview /> },
        { path: 'overview/:professionId', element: <EvaluationOverview /> },
        { path: 'course-detail/:courseId', element: <EvaluationDetail /> },
        { path: 'question-detail/:courseId/:lessonId', element: <EvaluationQuestionDetail /> }
      ]
    },
    {
      path: 'lms',
      element: <DashboardLayout />,
      children: [
        { element: <LmsOverview />, index: true },
        {
          path: 'organisations/:orgId',
          element: <LmsOrganisationDetail />
        }
      ]
    },
    {
      path: 'course',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/course/list" replace />,
          index: true
        },
        {
          path: 'list',
          element: <CourseContainerSelect />
        },
        {
          path: 'list/:packageId/:courseContainerId',
          element: <CourseContainerCourseList />
        },
        {
          path: ':courseId/students/active',
          element: <CourseActiveStudents />
        }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const UserDetail = Loadable(lazy(() => import('../pages/user/UserDetail')));

const Trials = Loadable(lazy(() => import('../pages/marketing/Trials')));
const TrialDetail = Loadable(lazy(() => import('../pages/marketing/TrialDetail')));
const TrialCreateSingle = Loadable(lazy(() => import('../pages/marketing/TrialCreateSingle')));
const TrialCreateBatch = Loadable(lazy(() => import('../pages/marketing/TrialCreateBatch')));

const SpecialAccess = Loadable(lazy(() => import('../pages/specialAccess/SpecialAccess')));
const SpecialAccessInviteUser = Loadable(lazy(() => import('../pages/specialAccess/InviteUser')));
const SpecialAccessGiveDirectAccess = Loadable(lazy(() => import('../pages/specialAccess/GiveDirectAccess')));

const AuthorList = Loadable(lazy(() => import('../pages/author/AuthorList')));
const AuthorCreate = Loadable(lazy(() => import('../pages/author/AuthorCreate')));
const AuthorDetail = Loadable(lazy(() => import('../pages/author/AuthorDetail')));

const LinkCheckerList = Loadable(lazy(() => import('pages/linksChecker/LinkCheckerList')));

const BannerList = Loadable(lazy(() => import('../pages/banner/BannerList')));
const BannerCreate = Loadable(lazy(() => import('../pages/banner/BannerCreate')));
const BannerEdit = Loadable(lazy(() => import('../pages/banner/BannerEdit')));

const FreeUploadList = Loadable(lazy(() => import('pages/uploadMedia/FreeUploadList')));
const FreeUploadCreate = Loadable(lazy(() => import('pages/uploadMedia/FreeUploadCreate')));

const EventList = Loadable(lazy(() => import('pages/vtb/event/EventList')));

const SyncContact = Loadable(lazy(() => import('pages/sync/SyncContact')));
const SyncContract = Loadable(lazy(() => import('pages/sync/SyncContract')));

const EvaluationOverview = Loadable(lazy(() => import('pages/qualitySystem/EvaluationOverview')));
const EvaluationDetail = Loadable(lazy(() => import('pages/qualitySystem/EvaluationDetail')));
const EvaluationQuestionDetail = Loadable(lazy(() => import('pages/qualitySystem/QuestionDetail')));

const LmsOverview = Loadable(lazy(() => import('pages/lms/Overview')));
const LmsOrganisationDetail = Loadable(lazy(() => import('pages/lms/organisation/OrganisationDetail')));

const CourseContainerSelect = Loadable(lazy(() => import('pages/course/CourseContainerSelect')));
const CourseContainerCourseList = Loadable(lazy(() => import('pages/course/CourseContainerCourseList')));
const CourseActiveStudents = Loadable(lazy(() => import('pages/course/CourseActiveStudents')));
