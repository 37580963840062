import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // OR
  // const logo = '/logo/logo_single.svg';
  const logo = (
    <Box sx={{ width: 80, height: 40, ...sx }}>
      <img src="https://www.e-wise.nl/themes/ewise/images/ewise-logo.svg" alt="Logo" width="100%" />
    </Box>
  );

  if (disabledLink) {
    return <span>{logo}</span>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object
};
Logo.defaultProps = {
  disabledLink: false,
  sx: {}
};
