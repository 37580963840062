import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Link } from '@mui/material';
//
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from './Breadcrumbs';

// ----------------------------------------------------------------------
export default function HeaderBreadcrumbs({ back, links, action, heading, moreLink, sx, ...other }) {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {back && (
            <Link component={RouterLink} to={back.to}>
              <Typography variant="subtitle1" gutterBottom>
                {t('goBackTo')}: {back.text}
              </Typography>
            </Link>
          )}
          <Typography variant="h4">{heading}</Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link noWrap key={href} href={href} variant="body2" target="_blank" rel="noopener" sx={{ display: 'table' }}>
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  back: PropTypes.shape({ to: PropTypes.string, text: PropTypes.string }),
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object
};
HeaderBreadcrumbs.defaultProps = {
  links: [],
  action: <div />,
  moreLink: [],
  sx: {}
};
