import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

//
// eslint-disable-next-line import/no-cycle
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.overline,
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  })
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const { t } = useTranslation('navigation');
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={uuidv4()} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0
              })
            }}
          >
            {t(group.subheader)}
          </ListSubheaderStyle>

          {group.items
            .map((item) => ({ ...item, title: t(item.title) }))
            .map((list) => (
              <NavListRoot key={uuidv4()} list={list} isCollapse={isCollapse} />
            ))}
        </List>
      ))}
    </Box>
  );
}

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array
};
NavSectionVertical.defaultProps = {
  isCollapse: false,
  navConfig: []
};
