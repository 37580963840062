import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, Typography, Tooltip } from '@mui/material';
//
import Iconify from '../../Iconify';
import { ListItemStyle as ListItem, ListItemTextStyle, ListItemIconStyle } from './style';
// eslint-disable-next-line import/no-cycle
import { isExternalLink } from '..';

// ----------------------------------------------------------------------
export const ShowIcon = (icon) => <Iconify icon={icon} sx={{ width: 16, height: 16, ml: 0.5 }} />;

// ----------------------------------------------------------------------
ListItem.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string)
};

export const NavItemRoot = ({ item, isCollapse, open = false, active, onOpen }) => {
  const { title, path, icon, info, children, disabled, caption, roles } = item;
  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{ShowIcon(icon)}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={`${title}`}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeRoot={active} disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={Link} href={path} target="_blank" rel="noopener" disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeRoot={active} disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  );
};

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string)
  })
};
NavItemRoot.defaultProps = {
  active: false,
  open: false,
  isCollapse: false,
  onOpen: () => {},
  item: {
    children: [],
    icon: '',
    info: '',
    path: '',
    title: '',
    disabled: false,
    caption: '',
    roles: ['']
  }
};

// ----------------------------------------------------------------------
export const NavItemSub = ({ item, open = false, active = false, onOpen }) => {
  const { title, path, info, children, disabled, caption, roles } = item;
  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={Link} href={path} target="_blank" rel="noopener" subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeSub={active} subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  );
};

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string)
  })
};
NavItemSub.defaultProps = {
  active: false,
  open: false,
  onOpen: () => {},
  item: {
    children: [],
    info: '',
    path: '',
    title: '',
    disabled: false,
    caption: false,
    roles: ['']
  }
};

// ----------------------------------------------------------------------
export const DotIcon = ({ active }) => (
  <ListItemIconStyle>
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter
          }),
        ...(active && {
          transform: 'scale(2)',
          bgcolor: 'primary.main'
        })
      }}
    />
  </ListItemIconStyle>
);

DotIcon.propTypes = {
  active: PropTypes.bool
};
DotIcon.defaultProps = {
  active: false
};

// ----------------------------------------------------------------------
export const ArrowIcon = ({ open }) => (
  <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ width: 16, height: 16, ml: 1 }} />
);

ArrowIcon.propTypes = {
  open: PropTypes.bool
};
ArrowIcon.defaultProps = {
  open: false
};
