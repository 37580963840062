import { PATH_DASHBOARD } from 'routes/paths';

export const marketing = {
  items: [
    {
      title: 'marketing.trials',
      path: PATH_DASHBOARD.marketing.trials,
      icon: 'bxs:coupon'
    }
  ]
};
