import { PATH_SPECIAL_ACCESS } from 'routes/paths';

export const specialAccess = {
  items: [
    {
      title: 'specialAccess.root',
      path: PATH_SPECIAL_ACCESS.root,
      icon: 'fa6-solid:user-lock'
    }
  ]
};
