import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
// ----------------------------------------------------------------------
const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet meta={meta}>
      <title>{`${title} | Admin Panel`}</title>
    </Helmet>
    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));
Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.array
};
Page.defaultProps = {
  title: '',
  meta: []
};
export default Page;
