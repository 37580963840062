/* eslint-disable no-template-curly-in-string */

const types = {
  number: 'a numerical value',
  bool: 'Yes / No',
  string: 'an alphanumeric value',
  array: 'a list of values',
  object: 'an object',
  date: 'a valid date'
};

const getTypeTranslation = (type) => types[type] || `a "${type}" type`;

// Based on https://github.com/jquense/yup/blob/b940eef48eb7456622ae384d0ffa7363d4fbad25/src/locale.ts
export const mixed = {
  default: 'The value is invalid.',
  required: 'This is a required field',
  defined: 'This field must be defined',
  notNull: 'This field cannot be empty',
  oneOf: 'The value must be one of the following: ${values}',
  notOneOf: 'The value cannot be one of the following: ${values}',
  notType: ({ type }) => `The value must be ${getTypeTranslation(type)}`
};

export const string = {
  length: 'The value must be exactly ${length} characters long',
  min: 'The value must be at least ${min} characters long',
  max: 'The value must be at most ${max} characters long',
  matches: 'The value must match the following pattern: "${regex}"',
  email: 'The value must be a valid email',
  url: 'The value must be a valid URL',
  uuid: 'The value must be a valid UUID',
  trim: 'The value cannot contain leading or trailing spaces',
  lowercase: 'The value can only contain lowercase letters',
  uppercase: 'The value can only contain uppercase letters'
};

export const number = {
  min: 'The value must be greater than or equal to ${min}',
  max: 'The value must be less than or equal to ${max}',
  lessThan: 'The value must be less than ${less}',
  moreThan: 'The value must be greater than ${more}',
  positive: 'The value must be a positive number',
  negative: 'The value must be a negative number',
  integer: 'The value must be an integer'
};

export const date = {
  min: 'The date must be later than ${min}',
  max: 'The date must be earlier than ${max}'
};

export const boolean = {
  isValue: 'The value field must be ${value}'
};

export const object = {
  noUnknown: 'The value cannot contain keys not specified in the object'
};

export const array = {
  min: 'The value must contain at least ${min} items',
  max: 'The value must not contain more than ${max} items',
  length: 'The value must have ${length} items'
};
