import { PATH_DASHBOARD } from 'routes/paths';

export const vtb = {
  items: [
    {
      title: 'vtb.title',
      path: PATH_DASHBOARD.vtb.overview,
      icon: 'fa6-solid:calendar'
    }
  ]
};
