import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
//
// eslint-disable-next-line import/no-cycle
import { NavItemRoot, NavItemSub } from './NavItem';
import { PaperStyle } from './style';
import { getActive } from '..';

// ----------------------------------------------------------------------
export const NavListRoot = ({ list }) => {
  const menuRef = useRef(null);
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(false);
  const hasChildren = list.children;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [open, pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  if (hasChildren) {
    return (
      <>
        <NavItemRoot open={open} item={list} active={active} ref={menuRef} onMouseEnter={handleOpen} onMouseLeave={handleClose} />
        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose
          }}
        >
          {(list.children || []).map((item) => (
            <NavListSub key={item.title + item.path} list={item} />
          ))}
        </PaperStyle>
      </>
    );
  }

  return <NavItemRoot item={list} active={active} />;
};

NavListRoot.propTypes = {
  list: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string
  })
};
NavListRoot.defaultProps = {
  list: {
    children: [],
    path: ''
  }
};

// ----------------------------------------------------------------------
const NavListSub = ({ list }) => {
  const menuRef = useRef(null);
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub ref={menuRef} open={open} item={list} active={active} onMouseEnter={handleOpen} onMouseLeave={handleClose} />
        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose
          }}
        >
          {(list.children || []).map((item) => (
            <NavListSub key={item.title + item.path} list={item} />
          ))}
        </PaperStyle>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
};

NavListSub.propTypes = {
  list: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string
  })
};
NavListSub.defaultProps = {
  list: {
    children: [],
    path: ''
  }
};
export default NavListRoot;
