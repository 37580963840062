import { useContext } from 'react';
import { OauthContext } from '../components/providers/OauthProvider';

const useUser = () => {
  const user = useContext(OauthContext);
  if (!user) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return user;
};

export default useUser;
