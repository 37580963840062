export { default as ThemeSwitcher } from './ThemeSwitcher';
export { default as SwitchTheme } from './SwitchTheme';
export { default as LmsSingletotals } from './LmsSingletotals';
export { default as LmsSumProductTotals } from './LmsSumProductTotals';
export { default as LmsSumLocationTotals } from './LmsSumLocationTotals';
export { default as LmsMapRoute } from './LmsMapRoute';
//
export const sleep = (time = 500) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
