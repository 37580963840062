import { PATH_DASHBOARD } from 'routes/paths';

export const sync = {
  items: [
    {
      title: 'sync.title',
      path: PATH_DASHBOARD.sync.overview,
      icon: 'fa6-solid:medal',
      hidden: true
    }
  ]
};
