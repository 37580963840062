/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OAuth2 } from 'state/api/helpers/oauth2';
import createAuthenticatedClient from 'state/api/AuthenticatedClient';
import parseJwt from '../../helpers/ParseJwt';

export const OauthContext = React.createContext({});
export const AuthedClientContext = React.createContext({});

/**
 * The Oauth provider and context are responsible for decimating the
 * authentication for this app
 *
 * it is best thought of as a middleware wrapping
 * the entire application and only
 * allowing access with valid credentials
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OauthProvider = ({ children, LoaderComponent }) => {
  const [user, setUser] = useState(null);

  /**
   * Helper function to convert a id to an env-var name.
   *
   * @param key
   * @returns {`REACT_APP_OAUTH_${string}_${string}`}
   */
  const convertThemeIDToEnvName = (key) => `REACT_APP_OAUTH_${key}`;

  const oauthClient = new OAuth2({
    redirectUrl: process.env[convertThemeIDToEnvName('REDIRECT_URL')],
    clientId: process.env[convertThemeIDToEnvName('CLIENT_ID')],
    clientSecret: process.env[convertThemeIDToEnvName('CLIENT_SECRET')],
    authorizeEndpoint: process.env.REACT_APP_OAUTH_PROVIDER_AUTHORIZE_ENDPOINT,
    tokenEndpoint: process.env[convertThemeIDToEnvName('PROVIDER_DOMAIN')] + process.env.REACT_APP_OAUTH_PROVIDER_TOKEN_ENDPOINT,
    authorizationDomain: process.env[convertThemeIDToEnvName('PROVIDER_DOMAIN')]
  });

  /**
   * Register the behaviour for catching
   * requests made with bad creds
   */
  useEffect(() => {
    const oauthClientListener = () => {
      oauthClient.killSession();
    };

    /**
     * This is the event the api fires
     * when it detects invalid creds
     *
     * using the window as an eventbus as a hackaround
     */
    window.addEventListener('oauth_invalid', oauthClientListener);

    return () => {
      window.removeEventListener('oauth_invalid', oauthClientListener);
    };
  }, []);

  /**
   * We halt the rendering stack
   * until we have valid credentials
   */
  useEffect(() => {
    const init = async () => {
      try {
        const isInitialized = await oauthClient.initializeOauthSession();

        if (!isInitialized) {
          throw new Error('No token found');
        }

        const AuthedClient = createAuthenticatedClient(oauthClient);

        window.clientInstance = oauthClient;
        window.authedClient = AuthedClient;

        const token = await oauthClient.getToken();
        const accessTokenData = parseJwt(token.access_token);

        const { sub_uuid: id, scope, profile_picture: picture } = accessTokenData;

        const res = await window.authedClient.get(`${process.env.REACT_APP_JSONAPI}/oauth/userinfo`);

        if (res.status !== 200) {
          throw new Error('Error in getting user info');
        }

        setUser({ ...res.data, id, scope, picture });
      } catch (err) {
        if (err.response.status === 403) {
          await oauthClient.refreshToken();
          init();
        }
        console.error('Error in initializing oauth session', err);
      }
    };

    init();
  }, []);

  return (
    <>
      {user && (
        <OauthContext.Provider value={user}>
          <AuthedClientContext.Provider value={window.authedClient}>{children}</AuthedClientContext.Provider>
        </OauthContext.Provider>
      )}
      {!user && <LoaderComponent isLoading msg="LOADING" />}
    </>
  );
};

OauthProvider.propTypes = {
  children: PropTypes.object.isRequired,
  LoaderComponent: PropTypes.any
};

export default OauthProvider;
