import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { MenuItem, Stack } from '@mui/material';
// components
import { Image, MenuPopover } from '../../../components';
import { IconButtonAnimate } from '../../../components/animate';
// config
import { allLangs } from '../../../config';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(null);

  const lng = i18n.language.match('en') ? 'en' : 'nl';

  const currentLanguage = allLangs.find((i) => i.value === lng);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLanguageChange = (selectedLocale) => {
    i18n.changeLanguage(selectedLocale);
  };

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.resolvedLanguage);
    document.documentElement.setAttribute('dir', i18n.dir(i18n.resolvedLanguage));
  }, [i18n, i18n.resolvedLanguage]);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
          p: 1.1
        }}
      >
        <Image
          disabledEffect
          sx={
            {
              // pt: 0,
              // width: 20,
            }
          }
          src={currentLanguage?.icon}
          alt={currentLanguage?.label}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguage?.value}
              onClick={() => {
                handleLanguageChange(option.value);
                handleClose();
              }}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{
                  width: 28,
                  height: 28,
                  mr: 2,
                  pt: 0
                }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
