/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { createContext, useState, useEffect, useMemo } from 'react';
// hooks
import { localStorageHas, localStorageGet, localStorageSave } from 'helpers/LocalStorage';
import useResponsive from '../hooks/useResponsive';
// ----------------------------------------------------------------------
const initialState = {
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {}
};
const CollapseDrawerContext = createContext(initialState);

// ----------------------------------------------------------------------
const CollapseDrawerProvider = ({ children }) => {
  const isDesktop = useResponsive('up', 'lg');
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false
  });
  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false
      });
    } else if (localStorageHas('[global]-adminbar')) {
      setCollapse(localStorageGet('[global]-adminbar'));
    } else {
      setCollapse({
        click: false,
        hover: false
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
    localStorageSave('[global]-adminbar', collapse);
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
      localStorageSave('[global]-adminbar', collapse);
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
    localStorageSave('[global]-adminbar', collapse);
  };

  const contextValue = useMemo(
    () => ({
      isCollapse: collapse.click && !collapse.hover,
      collapseClick: collapse.click,
      collapseHover: collapse.hover,
      onToggleCollapse: handleToggleCollapse,
      onHoverEnter: handleHoverEnter,
      onHoverLeave: handleHoverLeave
    }),
    [collapse.click, collapse.hover, handleHoverEnter, handleHoverLeave, handleToggleCollapse]
  );

  return <CollapseDrawerContext.Provider value={contextValue}>{children}</CollapseDrawerContext.Provider>;
};

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node
};
CollapseDrawerProvider.defaultProps = {
  children: <div />
};
export { CollapseDrawerProvider, CollapseDrawerContext };
