import { Suspense } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// routes
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ScrollToTop } from './components';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import OauthProvider from './components/providers/OauthProvider';
import './index.css';
import { LoadingAuthPage } from './components/authorize';
// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <OauthProvider LoaderComponent={LoadingAuthPage}>
        <MotionLazyContainer>
          <ThemeProvider>
            <ThemeSettings>
              <NotistackProvider>
                <ProgressBarStyle />
                <ScrollToTop />
                <Suspense fallback="...is loading">
                  <Router />
                </Suspense>
              </NotistackProvider>
            </ThemeSettings>
          </ThemeProvider>
        </MotionLazyContainer>
      </OauthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
