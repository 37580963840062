import { PATH_DASHBOARD } from 'routes/paths';

export const users = {
  items: [
    {
      title: 'users.title',
      path: PATH_DASHBOARD.user.list,
      icon: 'fa6-solid:users'
    }
  ]
};
