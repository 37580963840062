import { PATH_DASHBOARD } from 'routes/paths';

export const lms = {
  items: [
    {
      title: 'lms.overview',
      path: PATH_DASHBOARD.lms.overview,
      icon: 'ph:student-fill'
    }
  ]
};
