import PropTypes from 'prop-types';
import { LazyMotion } from 'framer-motion';
// ----------------------------------------------------------------------
// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.jsx').then((res) => res.default);

export default function MotionLazyContainer({ children }) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}

MotionLazyContainer.propTypes = {
  children: PropTypes.node
};
MotionLazyContainer.defaultProps = {
  children: <div />
};
