import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ThemeSwitcher } from '../../helpers';

const EwiseTheme = ThemeSwitcher();
const logoPath = `${process.env.PUBLIC_URL}/${EwiseTheme}/assets/images/`;
/* Our default styles */
const useStyles = makeStyles({
  logo: {
    textAlign: 'center',
    '& img': {
      maxWidth: '40px',
      height: 'auto',
      margin: '10px',
      marginRight: '15px',
      cursor: 'pointer',
      width: '100%'
    }
  },
  flex: {
    display: 'flex'
  }
});

/* Return to homepage */
const goHome = (event) => {
  event.preventDefault();
  window.location.href = '/';
};

/* Our logo */
const AuthLogo = (props) => {
  const classes = useStyles();
  const { style, flex } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={clsx(classes.logo, { [classes.flex]: flex })} onClick={goHome}>
      <img src={`${logoPath}logo.svg`} alt="logo" className="logo" id="logo" style={style} />
    </div>
  );
};

/* Our logo props */
AuthLogo.propTypes = {
  style: PropTypes.object,
  flex: PropTypes.bool
};
AuthLogo.defaultProps = {
  style: {},
  flex: false
};
AuthLogo.defaultProps = {
  flex: false
};
export default AuthLogo;
