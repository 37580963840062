/* eslint-disable react/prop-types */
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const locationRef = useRef();
  useEffect(() => {
    if (!locationRef.current) {
      locationRef.current = location;
      return;
    }

    if (location.pathname !== locationRef.current.pathname) {
      resetErrorBoundary();
    }
  }, [location, resetErrorBoundary]);

  return (
    <Box
      sx={(theme) => ({
        width: '800px',
        maxWidth: '100%',
        margin: 'auto',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(3)
      })}
    >
      <Typography variant="h5" gutterBottom>
        {t('errorBoundary.title')}
      </Typography>
      <Box>{error.toString()}</Box>
      <Button onClick={resetErrorBoundary} variant="contained" color="primary">
        {t('errorBoundary.button')}
      </Button>
    </Box>
  );
};

export default ErrorFallback;
