import { PATH_DASHBOARD } from 'routes/paths';

export const course = {
  items: [
    {
      title: 'courseList.title',
      path: PATH_DASHBOARD.course.list,
      icon: 'fa6-solid:book-open'
    }
  ]
};
