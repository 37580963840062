import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { navConfig } from 'layouts/dashboard/navbar/navConfig/navConfig';
import useUser from './useUser';

export const useProtectedView = () => {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const allAllowedRoutes = user.scope
      .map((scopeItem) => navConfig[scopeItem.toLowerCase()])
      .filter(Boolean)
      .flat(1)
      .map((item) => item.items)
      .flat(1)
      .map((item) => item.path.split('/')[1]);

    const rootPath = location.pathname.split('/')?.[1];

    if (allAllowedRoutes && rootPath && !allAllowedRoutes.includes(rootPath)) {
      navigate(`/${allAllowedRoutes?.[0]}`);
    }
  }, [location, navigate, user]);
};
