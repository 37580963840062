import PropTypes from 'prop-types';

// ----------------------------------------------------------------------
export default function SvgIconStyle(/* { src, sx } */) {
  return 'asd';
}

SvgIconStyle.propTypes = {
  src: PropTypes.string.isRequired,
  sx: PropTypes.object
};
