import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { navConfig as baseNavConfig } from 'layouts/dashboard/navbar/navConfig/navConfig';
import useUser from './useUser';

export const useNavConfig = () => {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [navConfig, setNavConfig] = useState();

  useEffect(() => {
    const allAllowedRoutes = user.scope
      .map((scopeItem) => baseNavConfig[scopeItem.toLowerCase()])
      .filter(Boolean)
      .flat()
      .reduce((acc, current) => {
        const foundItem = acc.find((item) => item.items[0].title === current.items[0].title);

        if (!foundItem) {
          return acc.concat([current]);
        }

        return acc;
      }, []);

    setNavConfig(allAllowedRoutes);
  }, [location, navigate, user]);

  return navConfig;
};
