import { PATH_DASHBOARD } from 'routes/paths';

export const qualitySystem = {
  items: [
    {
      title: 'qualitySystem.title',
      path: PATH_DASHBOARD.qualitySystem.overview,
      icon: 'fa6-solid:medal'
    }
  ]
};
