import { PATH_DASHBOARD } from 'routes/paths';

export const author = {
  items: [
    {
      title: 'author.title',
      path: PATH_DASHBOARD.author.list,
      icon: 'fa6-solid:pen-nib'
    }
  ]
};
