import { PATH_DASHBOARD } from 'routes/paths';

export const uploadMedia = {
  items: [
    {
      title: 'freeUploadList.title',
      path: PATH_DASHBOARD.uploadMedia.list,
      icon: 'fa6-solid:images'
    }
  ]
};
